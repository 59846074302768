import * as React from "react"
import "./css/navbar.scss"
import { Link } from "gatsby"

const links = [
  {
    name: "Platform",
    link: "/#platform"
  },
  {
    name: "Applications",
    link: "/#applications"
  },
  {
    name: "Partners",
    link: "/#partners"
  },
  {
    name: "Funders",
    link: "/#funders"
  },
  {
    name: "Investors",
    link: "/#investors"
  },
  {
    name: "News",
    link: "/#news"
  },
  {
    name: "Company",
    children: [
      {
        name: "Management",
        link: "/company"
      },
      {
        name: "Board of Directors",
        link: "/company#board"
      }
    ]
  },
  {
    name: "Contact",
    link: "/#contact"
  }
]

const dropdownFragment = (item, setMenu) => {
  return (
    <li className="nav-item dropdown" key={item.name}>
      <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown">{item.name}</a>
      <ul className="dropdown-menu">
        {
          item.children.map(c => {
            return (
              <li key={c.name}><Link to={c.link} className="dropdown-item" onClick={() => setMenu(false)}>{c.name}</Link></li>
            )
          })
        }
      </ul>
    </li>
  )
}

export function Navbar({ children, menu, setMenu }) {
  const offCanvasStyle = {
    visibility: menu ? 'visible' : 'hidden'
  }
  return (
      <div id="navbarNav" className={menu ? 'offcanvas offcanvas-end show' : 'offcanvas offcanvas-end'} style={offCanvasStyle}>
        <div className="offcanvas-header border-bottom">
          <h5 className="offcanvas-title">Menu</h5>
          <button onClick={() => setMenu(!menu)} type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {
              links.map(link => {
                if (link.children) return dropdownFragment(link, setMenu)
                return (
                  <li className="nav-item" key={link.name}>
                    <Link to={link.link} className="nav-link" onClick={() => setMenu(false)}>{link.name}</Link>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
  )
}
